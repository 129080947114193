const MASK_FIELD = '*';

export class MaskedUtils {
    private constructor() {
    }
    /**
     * Validates a field whether it is masked or not.
     * Returns true, if the field is masked.
     */
    static isMasked(field?: string) {
        return field && field.indexOf(MASK_FIELD) > -1 ? true: false;
    }
}